import {
  Alert, Box, Link, Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { useForgotPasswordMutation } from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import Container from '../../components/app/Container';
import ErrorAlert from '../../components/app/ErrorAlert';
import HtmlForm from '../../components/app/HtmlForm';
import LoadingButton from '../../components/app/LoadingButton';
import SEO from '../../components/app/SEO';
import Form from '../../field/Form';
import InputField from '../../field/InputField';
import handleSubmitAction from '../../helpers/handleSubmitAction';
import { isRequired } from '../../helpers/validators';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { SubmitHandler } from '../../types/app';
import { ForgotPasswordFormData } from '../../types/form';

const ForgotPassword = () => {
  useAuthRedirect();

  const [forgotPasswordAction, { data }] = useForgotPasswordMutation();

  const handleSubmit = useCallback<SubmitHandler<ForgotPasswordFormData>>(async (
    values,
    helpers,
  ) => {
    await forgotPasswordAction(values).unwrap();
    helpers.resetForm();
  }, [forgotPasswordAction]);

  return (
    <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <SEO title="Forgot Password" />
      <Container maxWidth="sm">
        <Typography variant="h3" component="h1" fontWeight="600" textAlign="center" marginBottom={3}>Forgot Password</Typography>

        <Form<ForgotPasswordFormData>
          initialValues={{ identifier: '' }}
          onSubmit={handleSubmitAction(handleSubmit)}
        >
          {(config) => (
            <HtmlForm onSubmit={config.handleSubmit}>
              {data?.message && <Alert severity="success" variant="filled" sx={{ marginBottom: 3 }}>{data.message}</Alert>}
              <ErrorAlert message={config.errors._server?.message} />
              <InputField
                name="identifier"
                label="Email address / Username"
                fullWidth
                helperText="Fill your email address or username and we will send you a reset password link"
                rules={[isRequired]}
              />
              <LoadingButton loading={config.isLoading} type="submit" fullWidth>Send verification link</LoadingButton>

              <Box marginTop={1}>
                <Link href="/auth/signin">Back to Sign in</Link>
              </Box>
            </HtmlForm>
          )}
        </Form>
      </Container>
    </Container>
  );
};

export default ForgotPassword;
